import React, { Component } from 'react';

import ProgressBar from '@ramonak/react-progress-bar'

class ProgressSkills extends Component {
    render() {
    return (
        <div className="ContentSkills">
            <p>React, Next.js</p>
            <ProgressBar className="ProgressBarSkill" completed={90} isLabelVisible={false} bgColor="#199cdb" height="15px" />

            <p>Wordpress, Woocommerce</p>
            <ProgressBar className="ProgressBarSkill" completed={100} isLabelVisible={false} bgColor="#199cdb" height="15px" />

            <p>GraphQL</p>
            <ProgressBar className="ProgressBarSkill" completed={50} isLabelVisible={false} bgColor="#199cdb" height="15px" />

            <p>Javascript, Node.js</p>
            <ProgressBar className="ProgressBarSkill" completed={90} isLabelVisible={false} bgColor="#199cdb" height="15px" />

            <p>PHP, Laravel, CakePHP</p>
            <ProgressBar className="ProgressBarSkill" completed={80} isLabelVisible={false} bgColor="#199cdb" height="15px" />

            <p>MySQL, PostgreSQL</p>
            <ProgressBar className="ProgressBarSkill" completed={60} isLabelVisible={false} bgColor="#199cdb" height="15px" />

            <p>MongoDB</p>
            <ProgressBar className="ProgressBarSkill" completed={50} isLabelVisible={false} bgColor="#199cdb" height="15px" />

            <p>React Native</p>
            <ProgressBar className="ProgressBarSkill" completed={80} isLabelVisible={false} bgColor="#199cdb" height="15px" />
        </div>
    );
    }
} export default ProgressSkills;
