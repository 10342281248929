import React from 'react';
import ReactDOM from 'react-dom';

import App from './App.jsx';
import About from './pages/About/index.jsx';
import CodeLab from './pages/CodeLab/index.jsx';
import APIConnectPage from './pages/CodeLab/APIConnect/index.jsx';
import UIClonePage from './pages/CodeLab/UIClone/index.jsx';
import MobileAppsPage from './pages/CodeLab/MobileApps/index.jsx';
import ComponentsToolsPage from './pages/CodeLab/ComponentsTools/index.jsx';
import DevStacksPage from './pages/CodeLab/DevStacks/index.jsx';
import Contact from './pages/Contact/index.jsx';

import Page404 from './pages/404/index.jsx';

import { 
    BrowserRouter as Router, 
    Switch, 
    Route
} from 'react-router-dom';

ReactDOM.render(
  <Router>
        <Switch>
            <Route path="/" exact={true} component={App} />
            <Route path="/about" exact={true} component={About} />
            <Route path="/codelab" exact={true} component={CodeLab} />
            <Route path="/codelab/api-connect" exact={true} component={APIConnectPage} />
            <Route path="/codelab/ui-clone" exact={true} component={UIClonePage} />
            <Route path="/codelab/mobile-apps" exact={true} component={MobileAppsPage} />
            <Route path="/codelab/components-tools" exact={true} component={ComponentsToolsPage} />
            <Route path="/codelab/dev-stacks" exact={true} component={DevStacksPage} />
            <Route path="/contact" exact={true} component={Contact} />
            <Route path="*" component={Page404} />
        </Switch>
  </Router>,
  document.getElementById('root'));
