import React, { Component } from 'react';
import './MyResume.css';

import Masonry from 'react-masonry-css'

import expertiseIcon from './../../assets/img/briefcase.png'
import educationIcon from './../../assets/img/mortarboard.png'
import skillsIcon from './../../assets/img/skills.png'
import interestsIcon from './../../assets/img/teamwork.png'
import valuesIcon from './../../assets/img/diamond.png'
import languagesIcon from './../../assets/img/language.png'

import TravelIcon from './../../assets/img/baggage.png'
import MusicIcon from './../../assets/img/music-notes.png'
import LearnIcon from './../../assets/img/books.png'
import GameIcon from './../../assets/img/game-control.png'

import TimelineExpertise from './TimelineExpertise.jsx'
import TimelineEducation from './TimelineEducation.jsx'
import ProgressSkills from './ProgressSkills.jsx'
import TagCloudSkills from './TagCloudSkills.jsx';
import ProgressLanguage from './ProgressLanguage.jsx';

class MyResume extends Component {

    constructor (props) {
        super(props);
    
        this.state = {
          breakpointColumnsObj: {
            default: 3,
            1100: 3,
            700: 2,
            500: 1
          }
        };
    }

    handleChange() {
        if(this.initialState) {
          this.setState(this.initialState);
    
          delete this.initialState;
    
        } else {
          this.initialState = this.state;
    
          this.setState({
            breakpointColumnsObj: {
              default: 3,
              1100: 3,
              700: 3,
              500: 2
            }
          });
        }
    }

    render() {
    
    const breakpointColumnsObj = this.state.breakpointColumnsObj;

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            <div>
                <h2 className="titleAbout">Experiência</h2>
                <img className="iconResume" src={expertiseIcon} alt="Experiência" />
                <TimelineExpertise />
            </div>
            <div>
                <h2 className="titleAbout">Educação</h2>
                <img className="iconResume" src={educationIcon} alt="Educação" />
                <TimelineEducation />
            </div>
            <div>
                <h2 className="titleAbout">Habilidades</h2>
                <img className="iconResume" src={skillsIcon} alt="Habilidades" />
                <ProgressSkills />
            </div>
            <div>
                <h2 className="titleAbout">Interesses</h2>
                <img className="iconResume" src={interestsIcon} alt="Interesses" />
                <ul className="ListInterests">
                  <li><img className="InterestsImg" src={MusicIcon} alt="Música" /><span className="InterestsLabel">Música</span></li>
                  <li><img className="InterestsImg" src={TravelIcon} alt="Viagens" /><span className="InterestsLabel">Viagens</span></li>
                  <li><img className="InterestsImg" src={GameIcon} alt="Games" /><span className="InterestsLabel">Games</span></li>
                  <li><img className="InterestsImg" src={LearnIcon  } alt="Aprender" /><span className="InterestsLabel">Aprender</span></li>
                </ul>
            </div>
            <div>
                <h2 className="titleAbout">Conhecimentos</h2>
                <img className="iconResume" src={valuesIcon} alt="Conhecimentos" />
                <TagCloudSkills />
            </div>
            <div>
                <h2 className="titleAbout">Idiomas</h2>
                <img className="iconResume" src={languagesIcon} alt="Idiomas" />
                <ProgressLanguage />
            </div>
        </Masonry>

    );
    }
} export default MyResume;