import React, {Component} from 'react';
import './App.css';

import { Link } from 'react-router-dom';

import Header from './components/Header/index.jsx'
import SocialList from './components/SocialList/index.jsx';

class App extends Component {
    render() {
    return (
        <div className="AppWindow">
            <Header />
            <div className="container--userInfo">
                <span className="header--userName"><h1>&lt; Felipe Rosseti /&gt; <span className="BlinkCursor">|</span></h1></span>
                <span className="header--userProfession">Full Stack Developer</span>
            </div>
            <div className="container--socialInfo">
                <SocialList />
            </div>
            <div className="container--menuBoxes">
                <Link to="/about" className="linkBox">
                    <div className="itemBox">
                        <h2>Sobre Mim</h2>
                    </div>
                </Link>
                <Link to="/codelab" className="linkBox">
                    <div className="itemBox">
                        <h2>Code Lab</h2>
                    </div>
                </Link>
                <Link to="/contact" className="linkBox">
                    <div className="itemBox">
                        <h2>Fale comigo 👋</h2>
                    </div>
                </Link>
            </div>
        </div>
    );
    }
} export default App;