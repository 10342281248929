import React, { Component } from 'react';

import {
    Timeline,
    Container,
    YearContent,
    BodyContent,
    Section,
    Description,
   } from 'vertical-timeline-component-react';
   
   const customTheme = {
    yearColor: '#405b73',
    lineColor: '#d0cdc4',
    dotColor: '#262626',
    borderDotColor: '#d0cdc4',
    titleColor: '#405b73',
    subtitleColor: '#bf9765',
    textColor: '#262626',
   };

class TimelineExpertise extends Component {
    render() {
    return (
        <Timeline theme={customTheme} dateFormat='ll'>
            <Container>
                <YearContent startDate='2018' currentYear />
                <BodyContent>
                    <Section title='Jolivi, São Paulo'>
                        <Description variant='subtitle' text='Desenvolvedor Front-End Sr' />
                        <Description text='Desenvolvedor e líder do time de front-end. Hoje atuo como
Desenvolvedor Full Stack, provendo suporte para várias equipes.' />
                    </Section>
                </BodyContent>
            </Container>
            <Container>
                <YearContent startDate='2016' endDate='2020' />
                <BodyContent>
                    <Section title='Orbit Digital, São Paulo'>
                        <Description variant='subtitle' text='Co-Founder' />
                        <Description text='Projeto iniciado como trabalho de faculdade e que se tornou durante um
bom tempo única fonte de renda. Prestamos consultoria em desenvolvimento Web e Mobile.' />
                    </Section>
                </BodyContent>
            </Container>
            <Container>
                <YearContent startDate='2015' endDate='2017' />
                <BodyContent>
                    <Section title='Intelecto Soluções, São Paulo'>
                        <Description variant='subtitle' text='Desenvolvedor Front-End' />
                        <Description text='Fui contratado com objetivo de estruturar a operação de e-commerce e
UI e UX da loja virtual na plataforma VTEX.' />
                    </Section>
                </BodyContent>
            </Container>
            <Container>
                <YearContent startDate='2016' endDate='2020' />
                <BodyContent>
                    <Section title='Orbit Digital, São Paulo'>
                        <Description variant='subtitle' text='Especialista em E-commerce' />
                        <Description text='As principais atividades exercidas foram estruturação e
operacionalização do novo e-commerce da empresa.' />
                    </Section>
                </BodyContent>
            </Container>
        </Timeline>
    );
    }
} export default TimelineExpertise;
