import React, { Component } from 'react';
import './About.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faSkype } from '@fortawesome/free-brands-svg-icons'
import { faSmileWink } from '@fortawesome/free-regular-svg-icons'

import internetIcon from './../../assets/img/internet.png'
import mobileIcon from './../../assets/img/smartphone.png'
import shopIcon from './../../assets/img/online-shopping.png'

import { Link } from 'react-router-dom';

import Header from './../../components/Header/index.jsx'
import SocialList from './../../components/SocialList/index.jsx'
import MyResume from './../../components/MyResume/index.jsx'

import {CopyToClipboard} from 'react-copy-to-clipboard'

class About extends Component {
    state = {
        value: '',
        copied: false,
    };

    render() {

    return (
        <div className="AppWindow">
            <Link to="/" className="linkBack"><FontAwesomeIcon className="iconBack" icon={faChevronLeft} /></Link>
            <Header />
            <div className="MainSection">
                <div className="ContentBox">
                    <h2 className="titleAbout">Quem sou eu?</h2>
                    <h3>Full Stack Developer</h3>
                    <p>Apaixonado por eletrônica, robótica, e código. Sou desenvolvedor a mais de 10 anos, tendo atuado em diversas áreas de conhecimento. Sou defensor do <i>React</i>, <i>Flutter</i>, <i>WordPress</i>, <i>Gitflow</i> e <i>Pair Programming</i>. Não discuto sobre política, religião ou sobre a superioridade do MCU em relação a DC.</p>
                    <blockquote><span className="boxQuotationMark"><FontAwesomeIcon className="iconQuotationMark" icon={faQuoteLeft} /></span> <i>Programadores e artistas são os únicos profissionais que tem como hobby a própria profissão.</i></blockquote>
                    <a href="./curriculo-felipe-rosseti.pdf" className="BtDownloadCV" target="_blank" rel="noreferrer"><FontAwesomeIcon className="iconDownload" icon={faCloudDownloadAlt} /> Baixar meu CV</a>
                </div>
                
                <div className="ContentBox">
                    <h2 className="titleAbout">Informações Pessoais</h2>
                    <ul className="personalInfo">
                        <li><span className="BolderTitle">Aniversário:</span> <span className="CursorBirthday">09/04/1993 <span className="HiddenBirthday">🎁</span></span></li>
                        <li><span className="BolderTitle">WhatsApp:</span> <span className="CursorWhatsApp"> <a href="https://api.whatsapp.com/send?phone=5511994423320" className="linkWhatsApp" target="_blank" rel="noreferrer">+55 (11) 99442-3320</a> <span className="HiddenWhatsApp"> <FontAwesomeIcon className="iconWhatsApp" icon={faWhatsapp} /></span></span></li>
                        <li><span className="BolderTitle">E-mail:</span> <span className="CursorEmail">
                            <CopyToClipboard text="felipe@orbitdigital.com.br" 
                                onCopy={() => this.setState({copied: true})}>
                                <button className="CopyEmailLinkList">felipe@orbitdigital.com.br {this.state.copied ? <span className="TooltipCopyList">Copiado <FontAwesomeIcon className="iconCopy" icon={faCopy} /></span> : null} </button>
                            </CopyToClipboard>
                            </span></li>
                    <li><span className="BolderTitle">Skype:</span> <span className="CursorSkype"><a href="skype:felipe.ro7?chat" className="linkSkype">felipe.ro7</a> <span className="HiddenSkype"> <FontAwesomeIcon className="iconSkype" icon={faSkype} /></span></span> </li>
                        <li><span className="BolderTitle">Endereço:</span> <span className="CursorAdress">Rua José Bento de Souza Neto, 10 <FontAwesomeIcon className="pinAdress" icon={faMapMarkedAlt} /></span> </li>
                    </ul>
                    <SocialList />
                </div>
                
                <div className="ContentBox">
                    <h2 className="titleAbout">Minhas Especialidades</h2>
                    <div className="SkillBox">
                        <div className="BoxIconSkill">
                            <img className="iconSkill" src={internetIcon} alt="Web Developer" />
                        </div>
                        <div className="textSkill">
                            <h4>Web Developer</h4>
                            <p>Tenho vários anos fazendo, e estudando isso aqui, e mais de <i>500</i> projetos concluídos.</p>
                        </div>
                    </div>
                    <div className="SkillBox">
                        <div className="BoxIconSkill">
                            <img className="iconSkill" src={mobileIcon} alt="Mobile Developer" />
                        </div>
                        <div className="textSkill">
                            <h4>Mobile Developer</h4>
                            <p>Viva o Flutter <FontAwesomeIcon className="iconSmile" icon={faSmileWink} />! Já desenvolvi dezenas de aplicativos para Android e iOS.
                             </p>
                        </div>
                    </div>
                    <div className="SkillBox">
                        <div className="BoxIconSkill">
                            <img className="iconSkill" src={shopIcon} alt="E-commerce" />
                        </div>
                        <div className="textSkill">
                            <h4>E-commerce</h4>
                            <p>Desenvolvi vários e-commerces ao longo de anos, utilizando as mais variadas plataformas.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ResumeSection">
                <h2 className="titleAbout">Meu Resumo</h2>
                <MyResume />
            </div>
            <div className="TestimonialsSection">

            </div>
        </div>
    );
    }
} export default About;