import React, { Component } from 'react';
import './APIConnect.css';
import './../../../assets/common/Common.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom';

class APIConnect extends Component {
    render() {
    return (
        <div className="AppWindow">
            <Link to="/codelab" className="linkBack"><FontAwesomeIcon className="iconBack" icon={faChevronLeft} /></Link>
            WELCOME TO CODELAB - APIConnect PAGE!!!
        </div>
    );
    }
} export default APIConnect;