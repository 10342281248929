import React, { Component } from 'react';

import {
    Timeline,
    Container,
    YearContent,
    BodyContent,
    Section,
    Description,
   } from 'vertical-timeline-component-react';
   
   const customTheme = {
    yearColor: '#405b73',
    lineColor: '#d0cdc4',
    dotColor: '#262626',
    borderDotColor: '#d0cdc4',
    titleColor: '#405b73',
    subtitleColor: '#bf9765',
    textColor: '#262626',
   };

class TimelineEducation extends Component {
    render() {
    return (
        <Timeline theme={customTheme} dateFormat='L'>
            <Container>
                <YearContent className="YearTimeLine" startDate='2016' />
                <BodyContent>
                    <Section title='Faculdades Campos Salles'>
                        <Description variant='subtitle' text='Sistemas de Informação' />
                    </Section>
                </BodyContent>
            </Container>
            <Container>
                <YearContent className="YearTimeLine" startDate='2021' />
                <BodyContent>
                    <Section title='Faculdade Anhembi Morumbi'>
                        <Description variant='subtitle' text='Engenharia da Computação' />
                    </Section>
                </BodyContent>
            </Container>
        </Timeline>
    );
    }
} export default TimelineEducation;
