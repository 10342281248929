import React, {Component} from 'react';

import './Header.css'

import profilePhoto from './../../assets/img/profile-photo.jpg';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

class Header extends Component {
    state = {
        value: '',
        copied: false,
    };

    render() {
    return (
        <header>
            <img src={profilePhoto} className="header--profileImage" alt="Felipe Rosseti" />
            <span className="header--EmailText">Você tem alguma pergunta?<br /> Escreva para mim em&nbsp;
                <CopyToClipboard text="felipe@orbitdigital.com.br" 
                    onCopy={() => this.setState({copied: true})}>
                    <button className="CopyEmailLink">felipe@orbitdigital.com.br {this.state.copied ? <span className="TooltipCopy">Copiado <FontAwesomeIcon className="iconCopy" icon={faCopy} /></span> : null} </button>
                </CopyToClipboard>
            </span>
        </header>
    );
    }
} export default Header;