import React, { Component } from 'react';

import { TagCloud } from 'react-tagcloud'

const data = [
    { value: 'JavaScript', count: 40 },
    { value: 'React', count: 50 },
    { value: 'Node.js', count: 40    },
    { value: 'Express.js', count: 25 },
    { value: 'HTML5', count: 33 },
    { value: 'MongoDB', count: 18 },
    { value: 'CSS3', count: 20 },
    { value: 'React Native', count: 50 },
    { value: 'GraphQL', count: 20 },
    { value: 'MySQL', count: 15 },
    { value: 'AWS', count: 10 },
    { value: 'Ionic', count: 25 },
    { value: 'Mobile', count: 30 },
    { value: 'Jest', count: 20 },
    { value: 'Next.js', count: 25 },
    { value: 'Bootstrap', count: 20 },
    { value: 'Vue.js', count: 20 },
    { value: 'NPM', count: 20 },
    { value: 'Grunt', count: 20 },
    { value: 'Gulp', count: 20 },
    { value: 'Composer', count: 20 },
    { value: 'Yarn', count: 20 },
    { value: 'Python', count: 20 },
    { value: 'PostgreSQL', count: 20 },
    { value: 'DynamoDB', count: 20 },
    { value: 'API RESTful', count: 20 },
    { value: 'WooCommerce', count: 45 },
    { value: 'WordPress', count: 45 },
    { value: 'Magento', count: 20 },
    { value: 'Vtex', count: 20 },
    { value: 'JSON', count: 20 },
    { value: 'Salesforce', count: 20 },
    { value: 'Google Cloud', count: 20 },
    { value: 'DigitalOcean', count: 20 },
    { value: 'Heroku', count: 20 },
    { value: 'Microsoft Azure', count: 20 },
    { value: 'Git', count: 20 },
    { value: 'Jenkins', count: 20 },
    { value: 'Docker', count: 20 },
    { value: 'Vagrant', count: 20 },
    { value: 'UI', count: 20 },
    { value: 'UX', count: 20 },
    { value: 'PHP', count: 20 },
    { value: 'Laravel', count: 35 },
    { value: 'CakePHP', count: 35 },
  ]
  const options = {
    luminosity: 'light',
    hue: 'blue',
  }
class TagCloudSkills extends Component {
  render() {
    return (
      <div className='ContainerTagCloudSkills'>
        <TagCloud
            minSize={12}
            maxSize={35}
            tags={data}
            colorOptions={options}
        />
      </div>
    );
  }
}

export default TagCloudSkills;
