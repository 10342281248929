import React, { Component } from 'react';

import ProgressBar from '@ramonak/react-progress-bar'

class ProgressLanguage extends Component {
    render() {
    return (
        <div className="ContentSkills">
            <p>Português</p>
            <ProgressBar className="ProgressBarSkill" completed={90} isLabelVisible={false} bgColor="#199cdb" height="15px" />

            <p>Inglês</p>
            <ProgressBar className="ProgressBarSkill" completed={50} isLabelVisible={false} bgColor="#199cdb" height="15px" />

            <p>Italiano</p>
            <ProgressBar className="ProgressBarSkill" completed={20} isLabelVisible={false} bgColor="#199cdb" height="15px" />
        </div>
    );
    }
} export default ProgressLanguage;
