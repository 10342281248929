import React, { Component } from 'react';
import './Contact.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom';

class Contact extends Component {
    render() {
    return (
        <div className="AppWindow">
            <Link to="/" className="linkBack"><FontAwesomeIcon className="iconBack" icon={faChevronLeft} /></Link>
            WELCOME TO CONTACT PAGE!!!
        </div>
    );
    }
} export default Contact;