import React, {Component} from 'react';

import './SocialList.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faNpm } from '@fortawesome/free-brands-svg-icons'
import { faBehance } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faMedium } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'



class SocialList extends Component {
    render() {
    return (
        <ul className="ListIconSocial">
            <li className="socialItems"><a className="linkSocial" href="https://github.com/FelipeRosseti" target="_blank" rel="noreferrer"><FontAwesomeIcon  className="iconSocial"  icon={faGithub} /></a></li>
            <li className="socialItems"><a className="linkSocial" href="https://www.npmjs.com/~feliperosseti" target="_blank" rel="noreferrer"><FontAwesomeIcon  className="iconSocial"  icon={faNpm} /></a></li>
            <li className="socialItems"><a className="linkSocial" href="https://www.behance.net/feliperosseti" target="_blank" rel="noreferrer"><FontAwesomeIcon  className="iconSocial"  icon={faBehance} /></a></li>
            <li className="socialItems"><a className="linkSocial" href="https://www.linkedin.com/in/felipe-rosseti/" target="_blank" rel="noreferrer"><FontAwesomeIcon  className="iconSocial"  icon={faLinkedin} /></a></li>
            <li className="socialItems"><a className="linkSocial" href="https://medium.com/@felipe_rosseti" target="_blank" rel="noreferrer"><FontAwesomeIcon  className="iconSocial"  icon={faMedium} /></a></li>
            <li className="socialItems"><a className="linkSocial" href="https://www.instagram.com/frosseti/" target="_blank" rel="noreferrer"><FontAwesomeIcon  className="iconSocial"  icon={faInstagram} /></a></li>
            <li className="socialItems"><a className="linkSocial" href="https://twitter.com/FelipeRosseti4" target="_blank" rel="noreferrer"><FontAwesomeIcon  className="iconSocial" icon={faTwitter} /></a></li>
        </ul>
    );
    }
} export default SocialList;