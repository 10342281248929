import React, { Component } from 'react';
import './CodeLab.css';
import './../../assets/common/Common.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom';

class CodeLab extends Component {
    
    render() {
    return (
        <div className="AppWindow">
            <Link to="/" className="linkBack"><FontAwesomeIcon className="iconBack" icon={faChevronLeft} /></Link>
            WELCOME TO CODELAB PAGE!!!
            <br />
            <Link to="/codelab/api-connect" className="linkMenu">API Connect</Link>
            <br />
            <Link to="/codelab/ui-clone" className="linkMenu">UI Clone</Link>
            <br />
            <Link to="/codelab/mobile-apps" className="linkMenu">Mobile Apps</Link>
            <br />
            <Link to="/codelab/components-tools" className="linkMenu">Components & Tools</Link>
            <br />
            <Link to="/codelab/dev-stacks" className="linkMenu">Dev Stacks</Link>
        </div>
    );
    }
} export default CodeLab;